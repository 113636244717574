const generateAnywhereFeedUrl = ((anywhereConfig) => {
  return anywhereConfig.anywhereFeedUrl
    .replace(':domain:', anywhereConfig.domain)
    .replace(':API_User_Id:', anywhereConfig.apiUserId)
    .replace(':API_key:', anywhereConfig.apiKey)
    .replace(':target:', anywhereConfig.target)
    .replace(':language:', anywhereConfig.language)
    .replace(':feed:', anywhereConfig.feedId)
    .replace('{section}', `section=${anywhereConfig.sectionId}`)
    .replace('{pageIndex}', `pageIndex=${anywhereConfig.pageIndex}`)
    .replace('{pageSize}', `pageSize=${anywhereConfig.pageSize}`);
});

const initAnywhere = (() => {
  const appConfigPromise = httpGet(appConfigUrl)
    .then((appConfig) => {
      const feedUrl = generateAnywhereFeedUrl(appConfig.anywhere);

      const anywhereFeedPromise = httpGet(feedUrl)
        .then((anywhereFeed) => {

          const sectionConfig = {
            carouselConfig: appConfig.carousel,
          };

          initSection('anywhere-section', sectionConfig, anywhereFeed.sections);
        });
    });
});